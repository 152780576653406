import Link from 'next/link';

import { useContext, useEffect, useRef, useState } from 'react';
import { Context } from '@context/PreferenceContext';

import * as S from './CookieBar.sc';

import Button from '@components/Button/Button';

const CookieBar = () => {
  const {
    isCookieBarVisible,
    preferenceFunctional,
    preferenceStatistics,
    changePreferences,
  } = useContext(Context)!;
  const [isVisible, setIsVisible] = useState(false);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [showHeart, setShowHeart] = useState(false);

  const functionalCheckbox = useRef<HTMLInputElement>(null);
  const statisticsCheckbox = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setIsVisible(isCookieBarVisible);
  }, [isCookieBarVisible]);

  const handleAccept = () => {
    changePreferences({
      necessary: true,
      functional:
        !isOptionsVisible || (functionalCheckbox.current?.checked ?? false),
      statistics:
        !isOptionsVisible || (statisticsCheckbox.current?.checked ?? false),
    });
  };

  const handleReject = () => {
    changePreferences({
      necessary: false,
      functional: false,
      statistics: false,
    });
  };

  const showOptions = () => {
    setIsOptionsVisible(true);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <S.Wrapper>
      <S.Container>
        <S.Info>
          <S.Text>
            We use cookies to enhance your experience and provide additional
            functionality.{' '}
            <Link href="/cookies" passHref legacyBehavior>
              <S.Anchor>More information</S.Anchor>
            </Link>
          </S.Text>
          <S.Options isVisible={isOptionsVisible}>
            <S.Option>
              <input type="checkbox" id="necessary" checked disabled />{' '}
              <S.Label htmlFor="necessary">
                <strong>Necessary cookies</strong> make the site function
                correctly.
              </S.Label>
            </S.Option>
            <S.Option>
              <input
                defaultChecked={preferenceFunctional}
                type="checkbox"
                id="functional"
                ref={functionalCheckbox}
              />{' '}
              <S.Label htmlFor="functional">
                <strong>Functional cookies</strong> help us provide you with an
                improved and enhanced experience.{' '}
              </S.Label>
            </S.Option>
            <S.Option>
              <input
                defaultChecked={preferenceStatistics}
                type="checkbox"
                id="statistics"
                onChange={(event) => setShowHeart(event.target.checked)}
                ref={statisticsCheckbox}
              />{' '}
              <S.Label htmlFor="statistics">
                <strong>Statistical cookies</strong> help us understand what
                content is popular and how to make the site better.{' '}
                {showHeart ? (
                  <S.Love>
                    Thank you!
                    <S.Heart />
                  </S.Love>
                ) : null}
              </S.Label>
            </S.Option>
          </S.Options>
        </S.Info>
        <S.Buttons>
          {!isOptionsVisible && (
            <Button
              analytics={{
                category: 'CookieBar',
                action: 'click',
                label: 'Show Options',
              }}
              onClick={showOptions}
              text="Options"
              type="secondary"
            />
          )}
          {isOptionsVisible && (
            <Button
              analytics={{
                category: 'CookieBar',
                action: 'click',
                label: 'Submit',
                value: 'Reject',
              }}
              onClick={handleReject}
              text="Reject All"
              type="remove"
            />
          )}
          <Button
            analytics={{
              category: 'CookieBar',
              action: 'click',
              label: 'Submit',
              value: 'Accept',
            }}
            onClick={handleAccept}
            text="Accept"
          />
        </S.Buttons>
      </S.Container>
    </S.Wrapper>
  );
};

export default CookieBar;
