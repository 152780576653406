import styled from 'styled-components';

const getIcon = (color) => {
  const fillColor = encodeURIComponent(color);

  return `url("data:image/svg+xml;charset=utf-8,%3Csvg fill='${fillColor}' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 10.375c0-2.416-1.959-4.375-4.375-4.375s-4.375 1.959-4.375 4.375c0 1.127.159 2.784 1.75 4.375l7 5.25s5.409-3.659 7-5.25 1.75-3.248 1.75-4.375c0-2.416-1.959-4.375-4.375-4.375s-4.375 1.959-4.375 4.375'/%3E%3C/svg%3E")`;
};

export const Wrapper = styled.div`
  background: var(--theme-color-grey1);
  border-top: solid 1px var(--theme-color-grey3);
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 1;
`;

export const Container = styled.div`
  margin: 0 auto;
  max-width: ${($props) => $props.theme.maxHeaderWidth};

  @media ${(props) => props.theme.breakpoints.lg} {
    display: grid;
    grid-template-columns: 3fr 2fr;
  }
`;

export const Info = styled.div`
  padding: 1rem;
`;

export const Text = styled.p`
  font-size: 1.2rem;
  margin: 0;

  @media ${(props) => props.theme.breakpoints.lg} {
    font-size: 1.4rem;
  }
`;

export const Buttons = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  margin: 1rem;

  > * {
    margin-left: 1rem;
  }
`;

export const Options = styled.div<{ isVisible: boolean }>`
  align-items: center;
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  font-size: 1.4rem;
  justify-content: flex-start;
  padding: 0.5rem 0;
`;

export const Option = styled.div`
  display: flex;
`;

export const Label = styled.label`
  padding-left: 0.5rem;
`;

export const Love = styled.span`
  align-items: flex-start;
  color: red;
  display: inline-flex;
  font-weight: bold;
`;

export const Heart = styled.div`
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg fill='red' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 10.375c0-2.416-1.959-4.375-4.375-4.375s-4.375 1.959-4.375 4.375c0 1.127.159 2.784 1.75 4.375l7 5.25s5.409-3.659 7-5.25 1.75-3.248 1.75-4.375c0-2.416-1.959-4.375-4.375-4.375s-4.375 1.959-4.375 4.375'/%3E%3C/svg%3E");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  display: inline-block;
  height: 24px;
  margin: 0;
  width: 24px;
`;

export const Anchor = styled.a`
  text-decoration: underline;
`;
